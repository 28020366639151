@layer modules, ui, base;
@layer ui {
  /** @define IndicatorContainer */

/* stylelint-disable plugin/selector-bem-pattern */

.IndicatorContainer_jds-IndicatorContainer__kraPj {
  display: flex;
  align-items: center;
}

.IndicatorContainer_jds-IndicatorContainer__tag__vDO_T {
  display: flex;
  align-items: center;
  padding: 0 var(--spacer-100) 0 var(--spacer-150);
  border-radius: var(--radius-200);
  background-color: var(--color-grey--100);
  color: var(--color-black);
  font-size: var(--smallBody);
  line-height: var(--smallBodyLineHeight);
}

.IndicatorContainer_jds-IndicatorContainer__remove__DKZ_B {
  box-sizing: initial;
  padding: var(--spacer-50);
}

.IndicatorContainer_jds-IndicatorContainer__tag--disabled__1Sm7b {
  color: var(--color-grey--200);
}

@media (min-width: 48rem) {
  .IndicatorContainer_jds-IndicatorContainer--singleSelection__DY5Ey {
    display: none;
  }
}

@media (max-width: 63.999rem) {
  .IndicatorContainer_jds-IndicatorContainer--singleSelection__DY5Ey .IndicatorContainer_jds-IndicatorContainer__tag__vDO_T {
    background-color: transparent !important;
    color: var(--color-black) !important;
  }

  .IndicatorContainer_jds-IndicatorContainer--singleSelection__DY5Ey .IndicatorContainer_jds-IndicatorContainer__tag__vDO_T > span {
    display: none;
  }
}

}
@layer ui {
  /** @define Checkbox */

.checkbox_jds-Checkbox__tX9yi {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  color: var(--color-black);
  font-weight: var(--normal);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.checkbox_jds-Checkbox--disabled__dPiKi {
  color: var(--color-grey--200);
  cursor: default;
}

.checkbox_jds-Checkbox__input__Ekrqr {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox_jds-Checkbox__checker__7o3_v {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-100);
  background-color: var(--color-white);
}

.checkbox_jds-Checkbox__checker--withLabel__DMeay {
  margin-right: var(--spacer-100);
}

.checkbox_jds-Checkbox__tX9yi:hover > .checkbox_jds-Checkbox__input__Ekrqr:not(:disabled) + .checkbox_jds-Checkbox__checker__7o3_v,
.checkbox_jds-Checkbox__input__Ekrqr:focus + .checkbox_jds-Checkbox__checker__7o3_v {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.checkbox_jds-Checkbox__input__Ekrqr:disabled + .checkbox_jds-Checkbox__checker__7o3_v {
  border-color: var(--color-grey--100);
  background-color: var(--color-grey--100);
  cursor: default;
}

.checkbox_jds-Checkbox__input__Ekrqr:disabled + .checkbox_jds-Checkbox__checker--checked__SKul9,
.checkbox_jds-Checkbox__input__Ekrqr:disabled + .checkbox_jds-Checkbox__checker--indeterminate__vgewT {
  border-color: var(--color-grey--100);
  background-color: var(--color-grey--100);
}

.checkbox_jds-Checkbox__checker--checked__SKul9,
.checkbox_jds-Checkbox__checker--indeterminate__vgewT {
  border-color: var(--themeMainColor, var(--color-black));
  background-color: var(--themeMainColor, var(--color-black));
}

.checkbox_jds-Checkbox__checker--indeterminate__vgewT::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  width: 10px;
  border: 1px solid var(--color-white);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
[class*="jds-Icon"].checkbox_jds-Checkbox__tick__PLYM5 {
  position: absolute;
  width: 15px;
  height: 15px;
  stroke-width: 1px;
  stroke: var(--color-white);
  fill: var(--color-white);
}

}
@layer ui {
  /** @define Option */

.option_jds-Option___XgfL {
  /* stylelint-disable-next-line value-no-vendor-prefix */ /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  flex: 1;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: var(--normalBody);
}

.option_jds-Option__wrapper__21_Kn {
  display: flex;
}

.option_jds-Option__icon__RFr69 {
  visibility: hidden;
  box-sizing: content-box;
  padding: var(--spacer-50) 0 0 var(--spacer-100);
}

.option_jds-Option__icon--selected__LvQui {
  visibility: visible;
}

.option_jds-Option__outer__LNa4o:hover .option_jds-Option__TickBox__N8QqG {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.option_jds-Option__wrapper--grouped__LPeFX {
  margin-left: calc(var(--spacer-200) * -1);
}

}
@layer ui {
  /** @define MenuList */

.MenuList_jds-MenuList__block__Henbt:not(:last-child) {
  border-bottom: 1px solid var(--color-grey--100);
}

.MenuList_jds-MenuList__tagBlock__z_yUo {
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacer-100) var(--spacer-200);
  background: var(--color-white);
}

.MenuList_jds-MenuList__footer__TsAdN {
  position: sticky;
  bottom: 0;
  width: 100%;
  margin-top: -1px;
  border-top: 1px solid var(--color-grey--100);
  background: var(--color-white);
}

}
@layer ui {
  /** @apply CellApply */

.CellApply_jds-CellApply__5ywvC {
  display: flex;
  justify-content: space-between;
  padding: var(--spacer-100) var(--spacer-200);
}

}
@layer ui {
  /** @define ValueContainer */

/* stylelint-disable plugin/selector-bem-pattern */

.ValueContainer_jds-ValueContainer__ZtCHV {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
  gap: var(--spacer-50) !important;
}

.ValueContainer_jds-ValueContainer__ZtCHV[class*="jds-Select__value-container--has-value"][class*="jds-Select__value-container--is-multi"] {
  padding: 0 var(--spacer-50) 0 var(--spacer-200) !important;
}

.ValueContainer_jds-ValueContainer--isEmpty___8nnc {
  color: var(--color-grey--500);
}

.ValueContainer_jds-ValueContainer--isDisabled__2_RPo {
  color: var(--color-grey--200);
}

.ValueContainer_jds-ValueContainer__ZtCHV.ValueContainer_jds-ValueContainer--withIcon__PWihS svg {
  color: var(--color-black);
}

.ValueContainer_jds-ValueContainer__displayedValue__fEccl {
  position: relative;
  width: 100%;
}

.ValueContainer_jds-ValueContainer__displayedValue__fEccl::after {
  content: attr(data-placeholder);
  position: absolute;
  top: 50%;
  max-width: 90%;
  margin-left: var(--spacer-50);
  overflow: hidden;
  transform: translateY(-50%);
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.ValueContainer_jds-ValueContainer--isFocused__pKGdI .ValueContainer_jds-ValueContainer__displayedValue__fEccl::after {
  content: none;
}

.ValueContainer_jds-ValueContainer--isNotSearchable__lE1dD .ValueContainer_jds-ValueContainer__displayedValue__fEccl::after {
  margin-left: var(--spacer-100);
}

.ValueContainer_jds-ValueContainer--isNotSearchable__lE1dD .jds-Select__placeholder,
.ValueContainer_jds-ValueContainer--isNotSearchable__lE1dD .jds-Select__multi-value {
  display: none !important;
}

}
@layer ui {
  /** @define CellInformation */

.CellInformation_jds-CellInformation__xhI5D {
  padding: var(--spacer-150) var(--spacer-200);
  color: var(--color-grey--600);
}

}
@layer ui {
  /** @define Select */

/* we set global as workaround to post css over static class names
   further more we add jds-Select class to ensure css property priority */

.select_jds-Select__wigeC {
  --Select-innerHeight: calc(var(--field-height) - 2px); /* -2px for border */
  box-sizing: initial;
  flex: 1;
  width: 100%;
  font-size: var(--normalBody);
}

.select_jds-Select--hasError__ks179 .jds-Select__input:focus {
  border-color: transparent !important;
}

.select_jds-Select__wigeC .jds-Select__value-container {
  padding: 0 0 0 var(--spacer-200) !important;
}

.select_jds-Select__wigeC .jds-Select__control {
  min-height: var(--field-height);
  overflow: hidden;
  transition: none;
  border: thin solid var(--color-grey--200);
  border-radius: var(--radius-200);
  cursor: pointer;
}

.select_jds-Select__wigeC .jds-Select__control--is-focused.jds-Select__control--menu-is-open .jds-Select__single-value {
  /* Text or images of text that are part of an inactive user interface component have no contrast requirement */
  /* https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html */
  color: var(--color-grey--500);
}

.select_jds-Select__wigeC .jds-Select__control:hover {
  border-color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control:hover,
.select_jds-Select__wigeC .jds-Select__control--is-focused {
  outline: 2px solid var(--color-grey--500) !important; /* !important to surcharge internal library use of !important :/ */
  outline-offset: -1px;
}

.select_jds-Select--hasError__ks179 .jds-Select__control {
  border-color: var(--color-error--500);
}

.select_jds-Select--hasError__ks179 .jds-Select__control:hover,
.select_jds-Select--hasError__ks179 .jds-Select__control--is-focused {
  outline-color: var(--color-error--500) !important; /* !important to surcharge internal library use of !important :/ */
}

.select_jds-Select__wigeC .jds-Select__placeholder,
.select_jds-Select__wigeC .jds-Select__single-value,
.select_jds-Select__wigeC .jds-Select__input {
  margin: 0;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.select_jds-Select__wigeC .jds-Select__placeholder {
  overflow: hidden;
  color: var(--color-grey--500);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select_jds-Select__wigeC .jds-Select__single-value,
.select_jds-Select__wigeC .jds-Select__multi-value__label {
  /* stylelint-disable-next-line value-no-vendor-prefix */ /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  max-width: none;
  overflow: hidden;
  text-overflow: initial;
  white-space: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.select_jds-Select__wigeC .jds-Select__single-value {
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__menu {
  z-index: 2; /* Because label is 1 */
  overflow: hidden;
  border-radius: var(--radius-200);
  box-shadow: var(--default-shadow);
}

.select_jds-Select__wigeC .jds-Select__menu-list {
  max-height: 264px;
  padding: 0;
  border-bottom: 1px solid var(--color-grey--100);
}

.select_jds-Select__wigeC .jds-GroupedCheckbox .jds-Select__option {
  padding-left: calc(var(--spacer-200) * 2);
}

.select_jds-Select__wigeC .jds-Select__option {
  padding: var(--spacer-150) var(--spacer-200);
  background-color: var(--color-white);
  color: var(--color-black);
  font-weight: var(--normal);
}

.select_jds-Select__wigeC .jds-Select__option--is-selected {
  color: var(--color-black) !important;
}

.select_jds-Select__wigeC .jds-Select__option--is-focused,
.select_jds-Select__wigeC .jds-Select__option:hover,
.select_jds-Select__wigeC .jds-Select__option:focus {
  background-color: var(--color-grey--025);
}

.select_jds-Select__wigeC .jds-Select__input-container {
  flex: 0 1 auto;
  margin: 0;
  overflow: auto;
}

.select_jds-Select__wigeC .jds-Select__indicators {
  align-items: normal;
}

.select_jds-Select__wigeC .jds-Select__indicator {
  display: flex;
  align-items: center;
  height: var(--Select-innerHeight);
  padding: var(--spacer-150);
  transition: none;
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__clear-indicator,
.select_jds-Select__wigeC .jds-Select__loading-indicator {
  padding-right: calc(var(--spacer-150) / 2);
}

.select_jds-Select__wigeC .jds-Select__dropdown-indicator {
  padding-right: var(--spacer-200);
  padding-left: calc(var(--spacer-150) / 2);
}

.select_jds-Select__wigeC .jds-Select__indicator:hover {
  color: var(--color-grey--500);
}

.select_jds-Select__wigeC .jds-Select__option--is-disabled,
.select_jds-Select__wigeC .jds-Select__option--is-disabled:hover {
  background-color: transparent;
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control--is-disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
}

.select_jds-Select__wigeC .jds-Select__single-value--is-disabled {
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control--is-disabled .jds-Select__indicator {
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__control--is-disabled .jds-Select__placeholder {
  color: var(--color-grey--200);
}

.select_jds-Select__wigeC .jds-Select__group {
  padding: 0;
}

.select_jds-Select__wigeC .jds-Select__group-heading {
  margin: 0;
  padding: var(--spacer-300) var(--spacer-200) var(--spacer-50) var(--spacer-200);
  background-color: var(--color-white);
  color: var(--color-grey--600);
  font-size: var(--smallBody);
  font-weight: var(--normal);
  text-transform: none;
}

/* ******************************** */
/* MultiValue */
/* ******************************** */

.select_jds-Select__wigeC .jds-Select__multi-value {
  margin: var(--spacer-50);
  padding: 0 var(--spacer-100) 0 var(--spacer-150);
  border-radius: var(--radius-200);
  background-color: var(--color-grey--100);
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__multi-value__label {
  padding: 0;
  color: var(--black);
  font-size: var(--smallBody);
  line-height: var(--smallBodyLineHeight);
}

.select_jds-Select__wigeC .jds-Select__multi-value__remove {
  padding: var(--spacer-50);
  color: var(--color-black);
}

.select_jds-Select__wigeC .jds-Select__multi-value__remove:hover {
  background-color: inherit;
  color: inherit;
  cursor: pointer;
}

.select_jds-Select__wigeC .jds-Select__value-container--is-multi {
  max-height: 82px; /* 82 is ~2.5 line of tags */
  overflow: auto;
}

.select_jds-Select__wigeC .jds-Select__value-container--is-multi.jds-Select__value-container--has-value {
  padding: var(--spacer-100) 0 var(--spacer-100) var(--spacer-200);
}

.select_jds-Select__wigeC .jds-Select__multi-value--is-disabled {
  opacity: 0.5;
}

.select_jds-Select__wigeC .jds-Select__multi-value.jds-Select__multi-value--is-disabled {
  background-color: var(--color-grey--100);
}

.select_jds-Select__wigeC .jds-Select__multi-value--is-disabled .jds-Select__multi-value__label {
  color: var(--color-grey--500);
}

.select_jds-Select__wigeC .jds-Select__multi-value--is-disabled .jds-Select__multi-value__remove {
  color: var(--color-grey--200);
}

.select_jds-Select__linkedPictoWrapper__xRFYQ {
  --Select-linkedPictoSize: 32px;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.select_jds-Select__linkedPicto__JC3kh {
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--field-height);
  height: var(--field-height);
  margin-right: var(--spacer-100);
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-200);
  background: var(--color-white);
}

.select_jds-Select__wigeC:hover + .select_jds-Select__linkedPicto__JC3kh:not(.select_jds-Select__linkedPicto--disabled__ynUtu),
.select_jds-Select__wigeC:focus-within + .select_jds-Select__linkedPicto__JC3kh:not(.select_jds-Select__linkedPicto--disabled__ynUtu) {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.select_jds-Select__wigeC:hover + .select_jds-Select__linkedPicto__JC3kh.select_jds-Select__linkedPicto--error__zfSPl,
.select_jds-Select__wigeC:focus-within + .select_jds-Select__linkedPicto__JC3kh.select_jds-Select__linkedPicto--error__zfSPl {
  outline-color: var(--color-error--500);
}

.select_jds-Select__linkedPicto--disabled__ynUtu {
  background-color: var(--color-grey--025);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.select_jds-Select__linkedPicto--disabled__ynUtu > .select_jds-Select__linkedPicto--disabled__ynUtu {
  filter: grayscale(1);
  opacity: 0.3;
}

.select_jds-Select__linkedPicto--error__zfSPl:not(.select_jds-Select__linkedPicto--disabled__ynUtu) {
  border-color: var(--color-error--500);
}

.select_jds-Select__linkedPictoImg__mDw7_ {
  -o-object-fit: contain;
     object-fit: contain;
  width: var(--Select-linkedPictoSize);
  height: var(--Select-linkedPictoSize);
}

.select_jds-Select--noInteractionBorder__Bj57_ .jds-Select__control:hover,
.select_jds-Select--noInteractionBorder__Bj57_ .jds-Select__control--is-focused {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

}
@layer ui {
  /** @define CellCTA */

.CellCTA_jds-CellCTA__60Ck_ {
  padding: var(--spacer-150) var(--spacer-200);
}

}
@layer ui {
  .sk-Link_main__nOUpr {
  --Link-external-icon-caption1-size: var(--sk-size-12);
  --Link-external-icon-body2-size: var(--sk-size-16);
  --Link-external-icon-body1-size: var(--sk-size-20);
  --Link-external-icon-size: var(--Link-external-icon-body2-size);
  --Link-external-icon-data: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xNSA1Yy0uNTUyMyAwLTEtLjQ0NzcyLTEtMXMuNDQ3Ny0xIDEtMWg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxdjVjMCAuNTUyMjgtLjQ0NzcgMS0xIDFzLTEtLjQ0NzcyLTEtMXYtMi41ODU3OWwtOC4yOTI5IDguMjkyODljLS4zOTA1LjM5MDUtMS4wMjM2OC4zOTA1LTEuNDE0MjEgMC0uMzkwNTItLjM5MDUtLjM5MDUyLTEuMDIzNyAwLTEuNDE0Mmw4LjI5MjkxLTguMjkyOXptLTExLjEyMTMyIDEuODc4NjhjLjU2MjYxLS41NjI2MSAxLjMyNTY3LS44Nzg2OCAyLjEyMTMyLS44Nzg2OGg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxcy0uNDQ3NyAxLTEgMWgtNWMtLjI2NTIyIDAtLjUxOTU3LjEwNTM2LS43MDcxMS4yOTI4OS0uMTg3NTMuMTg3NTQtLjI5Mjg5LjQ0MTg5LS4yOTI4OS43MDcxMXY5YzAgLjI2NTIuMTA1MzYuNTE5Ni4yOTI4OS43MDcxLjE4NzU0LjE4NzUuNDQxODkuMjkyOS43MDcxMS4yOTI5aDljLjI2NTIgMCAuNTE5Ni0uMTA1NC43MDcxLS4yOTI5cy4yOTI5LS40NDE5LjI5MjktLjcwNzF2LTVjMC0uNTUyMy40NDc3LTEgMS0xczEgLjQ0NzcgMSAxdjVjMCAuNzk1Ny0uMzE2MSAxLjU1ODctLjg3ODcgMi4xMjEzcy0xLjMyNTYuODc4Ny0yLjEyMTMuODc4N2gtOWMtLjc5NTY1IDAtMS41NTg3MS0uMzE2MS0yLjEyMTMyLS44Nzg3cy0uODc4NjgtMS4zMjU3LS44Nzg2OC0yLjEyMTN2LTljMC0uNzk1NjUuMzE2MDctMS41NTg3MS44Nzg2OC0yLjEyMTMyeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");

  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.sk-Link_main--external__hOR_4::after {
  content: "";
  display: inline-flex;
  width: var(--Link-external-icon-size);
  height: var(--Link-external-icon-size);
  margin-left: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  /* Use the same color of the link for the external icon. */
  background-color: currentcolor;
  vertical-align: sub;
  /* Required by Sandpack as it uses css as is. */
  -webkit-mask-image: var(--Link-external-icon-data);
  mask-image: var(--Link-external-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Link_main__nOUpr:focus-visible {
  outline-offset: 0;
  outline-style: solid;
  outline-width: var(--sk-navigation-link-size-border-focus);
}

.sk-Link_main--caption1__YoyQD {
  font-size: var(--sk-typography-navigation-link-text-typography-s-font-size);
  font-weight: var(--sk-typography-navigation-link-text-typography-s-font-weight);
  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--caption1__YoyQD.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-caption1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--body1__4qy4x {
  font-size: var(--sk-typography-navigation-link-text-typography-l-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body1__4qy4x.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body2__5CxGA {
  font-size: var(--sk-typography-navigation-link-text-typography-m-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--body2__5CxGA.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body2-size);

  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--light__sHPm0 {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Link_main--light__sHPm0:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.sk-Link_main--light__sHPm0:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Link_main--dark__PTzF2 {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Link_main--dark__PTzF2:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-dark);
}

.sk-Link_main--dark__PTzF2:hover {
  background-color: var(--sk-navigation-link-color-background-hover-dark);
  color: var(--sk-navigation-link-color-hover-dark);
}

}
